<!-- =========================================================================================
  File Name: ClientOrdersHistory.vue
  Description: История заказов посетителя в табличном виде
========================================================================================== -->
<template>
  <div class="mt-4">
    <vs-table 
        :data="data"
        :max-items="10"
        stripe
        hoverFlat
        noDataText="Нет заказов"
        v-if="data">

      <template slot="thead">
        <vs-th>#</vs-th>
        <vs-th>Дата</vs-th>
        <vs-th>Менеджер</vs-th>
        <vs-th>Сумма</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
            :state="tr.is_time_order == 1 ? 'primary' : ''">
          <vs-td :data="tr">
            <span v-if="tr.is_time_order == 1">Визит </span>
            {{tr.number}}
          </vs-td>
          <vs-td :data="tr">{{$helpers.formatDate(tr.time)}}</vs-td>
          <vs-td :data="tr">{{tr.manager_name}}</vs-td>
          <vs-td :data="tr">{{tr.actual_total_price}} руб.</vs-td>

          <template class="expand-order" slot="expand">
            <div class="con-expand-orders w-full">
              <div class="con-btns-user flex items-center justify-between">
                <div class="flex">
                  <vs-button type="border" size="small" color="success" class="mr-2"
                      @click="editOrderHandler(tr.id)">
                    Посмотреть
                  </vs-button>
                </div>
              </div>
              <vs-list>
                <vs-list-item
                  v-for="item in tr.items" :key="item.id"
                  :title="item.name">{{item.price}} руб. x {{item.item_amount}} шт. = {{item.total_price}} руб.</vs-list-item>
              </vs-list>

              <!-- Если заказ-время - вывести инфу о заказах внутри визита -->
              <div class="border border-dashed border-grey border-l-0  border-r-0 border-t-0 pl-8"
                  v-if="tr.is_time_order == 1 && tr.visit_id != 0 && !flat">
                <client-orders-history 
                  :editOrderHandler="editOrderHandler"
                  :visit_id="tr.visit_id" :customer_id="tr.customer_id" :flat="flat"/>
              </div>
            </div>
          </template>

        </vs-tr>
      </template>

    </vs-table>
  </div>
</template>

<script>
export default {
  name: 'client-orders-history',
  props: [
   'visit_id', 'customer_id', 'flat', 'editOrderHandler'
  ],
  data() {
    return {
      data: [],
      scrollSettings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  methods: {
    async getData() {
      await this.$http({
        method: 'get',
        url: '/udata/users/get_orders_history/.json' 
           + '?visit_id=' + this.visit_id 
           + '&customer_id=' + this.customer_id
           + '&flat=' + this.flat,
        withCredentials: true
      }).then((response) => {
          if (!response.data.error) {
              this.data = Object.values(response.data.items);
          } else {
            this.$vs.notify({
              title:'Ошибка',
              text: response.data.error,
              color:'warning',
              iconPack: 'feather',
              icon:'icon-alert-circle'
            })
          }
      })
      .catch((error) => {
        this.$vs.notify({
          title:'Ошибка',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      });
    }
  },
  mounted() {
    this.getData();
  }
}
</script>
