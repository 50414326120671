<!-- =========================================================================================
  File Name: OrderStatusChip.vue
  Description: Бэджик с выводом информации о статусе заказа
========================================================================================== -->
<template>
    <div class="flex flex-row">
        <vs-chip :color="color" >
            <vs-avatar :text="statusText" />
            {{name}}
        </vs-chip>
    </div>
</template>

<script>
export default {
    name: 'order-status-chip',
    props: [
        'status',
        'name',
    ],
    data() {
        return {
            statusColors: {
                0: 'primary',
                1: 'warning',
                2: 'success'
            }
        }
    },
    computed: {
        color() {
            return this.statusColors[this.status];
        },
        statusText() {
            return this.status.toString();
        }
    },
}
</script>
