<!-- =========================================================================================
	File Name: OrderPayments.vue
	Description: Блок выбора оплат заказа
========================================================================================== -->

<template>
    <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
            <vx-card title="Оплаты">
                <div class="mt-1">
                    <ul class="w-full"
                        v-if="paymentMethods.length">
                        <li class="flex text-sm mb-2"
                          v-for="(item, index) in paymentMethods" :key="index">
                            <div class="w-1/2">
                                <div>
                                    <span class="font-bold">{{paymentMethodName(item)}}</span>
                                    <span> - {{item.payment_amount.toString()}} руб.</span>
                                </div>
                                <p class="text-xs italic">
                                    {{ $helpers.formatDateTime(item.payment_time) }} - {{ item.manager_name }}
                                </p>
                            </div>
                            <div class="w-1/2 text-right">
                                <api-button
                                    :click="() => removePaymentMethod(index)" 
                                    v-if="isEditPaymentsAllowed"
                                    color="danger">
                                        X
                                </api-button>
                            </div>
                        </li>
                    </ul>
                </div>
            </vx-card>
        </div>

        <!-- Попап добавления оплат -->
        <vs-popup title="Добавить способ оплаты" :active.sync="paymentPrompt">
            <ul>
                <li v-for="type in paymentTypes" :key="type.id">
                    <div v-if="type.id != 84728">
                        <vs-radio v-model="paymentType" :vs-value="type">{{type.text}}</vs-radio>
                    </div>
                </li>
            </ul>
            <div class="con-exemple-prompt">
                <span>Введите сумму</span>
                <vs-input-number placeholder="Сумма" vs-placeholder="Сумма" v-model.number="paymentAmount"
                    min="0" :max="paymentAmountLeft" :step="0.01"/>
            </div>
            <vs-divider />
            <div class="flex flex-wrap">
                <api-button class="mb-2 mx-2 flex-auto" :click="addPaymentMethod">ДОБАВИТЬ ОПЛАТУ</api-button>
                <vs-button class="mb-2 mx-2 flex-auto" @click="paymentPrompt = false">ЗАКРЫТЬ</vs-button>
            </div>
        </vs-popup>

        <div class="vx-col lg:w-1/2 w-full">
            <vx-card title="Детали оплаты">
                <h3 class="font-semibold mb-3">Заказ # {{order.number}}</h3>
                <span class="text-grey">ID {{order.id}}</span>
                <vs-divider />
                <template v-if="order.is_time_order">
                    <div class="flex justify-between mb-2 italic">
                        <span>Стоимость минут</span>
                        <span class="font-semibold">{{ order.original_total_price }} руб.</span>
                    </div>
                    <div class="flex justify-between mb-2 italic">
                        <span>Корректировка до стопчека</span>
                        <span class="font-semibold">{{ -order.discount_value }} руб.</span>
                    </div>
                </template>
                <div class="flex justify-between mb-2">
                    <span>Всего к оплате</span>
                    <span class="font-semibold">{{ orderTotalPrice }} руб.</span>
                </div>
                <vs-divider />
                <div class="flex justify-between mb-2" v-if="(currentClientBonusesBalance >= orderTotalPrice) && currentClientBonusesBalance != 0">
                    <span>Будет оплачено бонусами</span>
                    <span class="text-success">{{ orderTotalPrice }} руб.</span>
                </div>
                <!-- <div class="flex justify-between mb-2" v-else-if="currentClientBonusesBalance < orderTotalPrice && currentClientBonusesBalance != 0">
                    <span>Оплачено бонусами</span>
                    <span class="text-success">{{ currentClientBonusesBalance }} руб.</span>
                </div> -->
                <div class="flex justify-between mb-2">
                    <span>Оплачено</span>
                    <span class="text-success">{{ paymentMethodsTotalAmount }} руб.</span>
                </div>
                <vs-divider />
                <div class="flex justify-between">
                    <span>Осталось оплатить</span>
                    <span class="font-semibold">{{ paymentAmountLeft }} руб.</span>
                </div>
                <template v-if="isEditPaymentsAllowed">
                    <vs-divider />
                    <api-button 
                        size="large" 
                        class="w-full" 
                        :disabled="paymentAmountLeft < 0"
                        :click="paymentAction">
                        {{paymentActionText}}
                    </api-button>
                </template>
            </vx-card>
        </div>
    </div>
</template>

<script>
import OrderPaymentsMixin from './OrderPaymentsMixin.vue';
export default {
    mixins: [OrderPaymentsMixin],
}
</script>
