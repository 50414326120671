<!-- =========================================================================================
  File Name: ClientInfo.vue
  Description: Информация о текущем посетителе
========================================================================================== -->
<template>
  <vx-card title="Информация о посетителе"
      slot="no-body" class="mb-4" collapse-action
      v-if="$store.state.activeClient">
    <vs-tabs>
      <vs-tab label="Посетитель">
        <div class="text-center">
          <h4>{{clientName}}</h4>
          <p class="text-grey">ID: {{$store.state.activeClient.id}}</p>
        </div>
        <vs-avatar class="mx-auto my-6 block" size="80px" :src="clientInfo.photo.src" 
          v-if="clientInfo.photo"/>
        <div class="mt-5 flex" v-if="clientInfo.client_type.id">
          <h6 class="mt-auto mb-auto mr-4">Тип клиента:</h6>
          <p>{{ clientInfo.client_type.name }}</p>
        </div>
        <div class="mt-5 flex">
            <h6 class="mt-auto mb-auto mr-4">Email:</h6>
            <p>{{clientInfo['e-mail']}}</p>
        </div>
        <div class="mt-5 flex" v-if="clientInfo.phone">
          <h6 class="mt-auto mb-auto mr-4">Телефон:</h6>
          <p class="flex items-center">
            <span>{{ $helpers.formatPhone(clientInfo.phone) }}</span>
            <vx-tooltip v-if="clientInfo.phone_verified" text="Телефон подтвержден" position="top" class="flex items-center ml-2">
              <vs-icon icon="done" color="green" size="21px" />
            </vx-tooltip>
            <template v-else>
              <vx-tooltip text="Телефон неподтвержден" position="top" class="flex items-center ml-2">
                <vs-icon icon="close" color="red" size="21px" />
              </vx-tooltip>
              <vs-button v-if="$acl.check('cashier')" @click="verifyPhonePrompt = true" size="small" color="danger" type="border" class="ml-6">
                Подтвердить
              </vs-button>
            </template>
          </p>
        </div>
        <div class="mt-5 flex">
          <h6 class="mt-auto mb-auto mr-4">Обновление пароля клиента:</h6>
          <p class="flex items-center">
            <vs-button v-if="$acl.check('cashier')" @click="updatePasswordPrompt = true" size="small" type="border" class="ml-6">
              Обновить пароль
            </vs-button>
          </p>
        </div>
        <div class="mt-5 flex" v-if="clientInfo.birthday">
            <h6 class="mt-auto mb-auto mr-4">День рождения:</h6>
            <p>{{$helpers.formatDate(clientInfo.birthday)}}</p>
        </div>
        <div class="mt-5 flex">
          <h6 class="mt-auto mb-auto mr-4">Бонусы:</h6>
          <div class="flex items-center">
            <span v-if="!showBalance"> {{ this.$store.state.activeClient.info.bonus }}</span>
            <span v-if="showBalance"> {{ bonusBalance }}</span>
            <div v-if="this.$store.state.userRole === 'admin'">
              <vs-button @click="addBonusesPrompt = true" size="small" type="border" class="ml-6">
                  Начислить
              </vs-button>
            </div>
            <vs-button @click="getCurrentBonuses" size="small" type="border" class="ml-6">
                Обновить сейчас
            </vs-button>
          </div>
        </div>
        <div class="social-links flex mt-4">
          <a :href="'https://facebook.com/' + clientInfo.facebook" target="_blank"
              v-if="clientInfo.facebook"> 
            <feather-icon svgClasses="h-10 w-10 bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon"></feather-icon>
          </a>
          <a :href="'https://instagram.com/' + clientInfo.instagram" target="_blank"
              v-if="clientInfo.instagram"> 
            <feather-icon svgClasses="h-10 w-10 bg-primary p-1 text-white rounded" class="mr-2" icon="InstagramIcon"></feather-icon>
          </a>
          <a :href="'https://vk.com/' + clientInfo.vkontakte" target="_blank"
              v-if="clientInfo.vkontakte"> 
            <feather-icon svgClasses="h-10 w-10 bg-primary p-1 text-white rounded" class="mr-2" icon="ChromeIcon"></feather-icon>
          </a>
        </div>
        <vs-button @click="editClientPrompt = true" size="large"
          v-if="$acl.check('cashier')"
          class="absolute top-0 right-0 mt-5" type="filled" color="success" icon="edit" />
      </vs-tab>

      <vs-tab label="Статистика">
        <ul>
          <li class="flex mb-3 py-3 justify-between border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-t-0">
              <span class="font-light italic">Бонусы</span>
              <span>{{ activeClient.stats.bonuses }}</span>
          </li>
          <li class="flex mb-3 py-3 justify-between border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-t-0">
              <span class="font-light italic">Всего визитов</span>
              <span>{{ activeClient.stats.visits_count }}</span>
          </li>
          <li class="flex mb-3 py-3 justify-between border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-t-0">
              <span class="font-light italic">Среднее время визита</span>
              <span>{{ $helpers.getTimeIntervalInMins(activeClient.stats.average_visit_duration) }} мин.</span>
          </li>
          <li class="flex mb-3 py-3 justify-between border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-t-0">
              <span class="font-light italic">Всего покупок</span>
              <span>{{ activeClient.stats.orders_count }}</span>
          </li>
          <li class="flex mb-3 py-3 justify-between border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-t-0">
              <span class="font-light italic">Средний чек</span>
              <span>{{ activeClient.stats.average_receipt }} руб.</span>
          </li>
        </ul>
      </vs-tab>

      <vs-tab label="Примечания">
        <div class="post-content">
          <p>{{clientInfo.about}}</p>
        </div>
        <client-comments />
      </vs-tab>

      <vs-tab label="История">
        <div>
          <p>История последних заказов и визитов</p>
        </div>
        <vs-tabs>
          <vs-tab label="Дерево">
            <client-orders-history
              :edit-order-handler="editOrder"
              :customer_id="activeClient.id" />
          </vs-tab>
          <vs-tab label="Список">
            <client-orders-history
              :edit-order-handler="editOrder"
              :customer_id="activeClient.id" :flat="true"/>
          </vs-tab>
        </vs-tabs>
      </vs-tab>

      <vs-tab label="Аналитика">
        <ul>
          <li v-for="(item, index) in activeClient.analytics" :key="index"
              class="flex mb-3 py-3 justify-between border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-t-0">
            <span class="font-light italic">{{item.title}}</span>
            <span>{{ item.value }}</span>
          </li>
        </ul>
      </vs-tab>

      <vs-tab label="Бонусы">
        <div>
          <p>История транзакций бонусов</p>
        </div>
          <client-bonus-history
            :customer_id="activeClient.id" />
      </vs-tab>

    </vs-tabs>

    <!-- Попап подтверждения телефона -->
    <vs-popup title="Подтверждение телефона" :active.sync="verifyPhonePrompt">
      <phone-verification-popup :customerId="activeClient.id" />
    </vs-popup>
    <!-- Попап редактирования клиента -->
    <vs-popup title="Редактирование данных клиента" :active.sync="editClientPrompt">
      <client-info-edit-form @clientSaved="editClientPrompt = false" finishButtonText="Сохранить" />
    </vs-popup>
    <!-- Попап начисления бонусов -->
    <vs-popup title="Изменение бонусного счета клиента" :active.sync="addBonusesPrompt">
      <add-bonuses-popup :customerId="activeClient.id" @close="addBonusesPrompt=false" finishButtonText="Начислить" />
    </vs-popup>
    <!-- Попап обновления пароля клиента -->
    <vs-popup title="Обновление пароля клиента" :active.sync="updatePasswordPrompt">
      <update-password-popup :customerId="activeClient.id" @close="updatePasswordPopup=false" finishButtonText="Обновить" />
    </vs-popup>
  </vx-card>
</template>

<script>
import ClientComments from './ClientComments.vue'
import ClientOrdersHistory from './ClientOrdersHistory.vue'
import ClientBonusHistory from './ClientBonusHistory.vue'
import ClientInfoEditForm from './ClientInfoEditForm.vue'
import PhoneVerificationPopup from './PhoneVerificationPopup.vue'
import AddBonusesPopup from './AddBonusesPopup.vue'
import UpdatePasswordPopup from './UpdatePasswordPopup.vue'

var qs = require('qs');

export default {
    components: {
      ClientComments,
      ClientOrdersHistory,
      ClientBonusHistory,
      ClientInfoEditForm,
      PhoneVerificationPopup,
      AddBonusesPopup,
      UpdatePasswordPopup,
    },
    name: 'client-info',
    data() {
      return {
        showBalance: false,
        bonusBalance: 'no_bonus',
        commentbox: '',
        editClientPrompt: false,
        verifyPhonePrompt: false,
        addBonusesPrompt: false,
        updatePasswordPrompt: false,
      }
    },
    computed: {
      activeClient() {
        return this.$store.state.activeClient;
      },
      clientInfo() {
        return this.$store.state.activeClient.info;
      },
      clientName() {
        let name = [this.clientInfo.lname, this.clientInfo.fname, this.clientInfo.father_name];
        return name.join(' ');
      },
      clientBonus() {
        let bonus = this.clientInfo.bonus;
        return bonus;
      }
    },
    methods: {
      getCurrentBonuses() {
        this.showBalance = false;
        if (this.$store.state.activeClient.id) {
          let data = {
                'data': {
                    'customer_id': this.$store.state.activeClient.id,
                }
            }
            this.$http({
                method: 'post',
                url: '/udata/users/get_customer_bonuses/.json',
                data: qs.stringify(data),
                withCredentials: true
            }).then((response) => {
                if (response.data.customer_bonuses) {
                    this.bonusBalance = response.data.customer_bonuses;
                    this.showBalance = true;
                } 
                else {
                    this.bonusBalance = 'no_bonus';
                    this.showBalance = true;
                }
            })
        }
      },
      editOrder(id) {
        this.$store.dispatch('getOrder', [
          id, function(){}, 
          () => {
            this.$router.push({ name: 'edit-order' }).catch(() => {});
          }
        ]);
      },
    },
    created() {
      this.getCurrentBonuses();
    }
}
</script>
