<!-- =========================================================================================
  File Name: MissedPaymentOrders.vue
  Description: Список заказов посетителя с задолженностями
========================================================================================== -->
<template>
  <div class="vx-col w-full lg:w-full mb-base"
      >
    <vx-card title="Незавершенные заказы" card-background="warning" title-color="#fff"
      v-if="unfinishedOrders.length > 0">
      <ul class="activity-timeline">
        <li v-for="order in unfinishedOrders" v-bind:key="order.id">
          <time-order v-if="order.is_time_order" v-bind:order="order" :tariffList="false" />
          <order v-else v-bind:order="order" />
        </li>
      </ul>
    </vx-card>
    <vx-card title="Задолженности" card-background="danger" title-color="#fff"
      v-if="missedPaymentOrders.length > 0">
      <ul class="activity-timeline">
        <li v-for="order in missedPaymentOrders" v-bind:key="order.id">
          <time-order v-if="order.is_time_order" v-bind:order="order" :tariffList="false" />
          <order v-else v-bind:order="order" />
        </li>
      </ul>
    </vx-card>
  </div>
</template>

<script>
import order from './Order.vue'
import timeOrder from './TimeOrder.vue'

export default {
  name: 'missed-payment-orders',
  data() {
    return {
      missedPaymentOrders: [],
      unfinishedOrders: []
    }
  },
  components: {
    order,
    timeOrder
  },
  methods: {
    async getOrders(api_method, orders) {
      let response;
      try {
        response = await this.$http.get('/udata/users/' + api_method + '/' + this.$store.state.activeClient.id + '/.json')
      } catch (error) {
        this.$vs.notify({
          title:'Ошибка ' + api_method,
          text:error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      }
      if (!response.data.error) {
        let data = response.data.orders;
        if (data) {
          data = Object.values(response.data.orders);
          data.forEach(order => {
            order.items = Object.values(order.items);
            order.payments = Object.values(order.payments);
          });
          this.$vs.notify({
            color:'warning',
            title:'У посетителя имеются неоплаченные заказы',
            text:'ID: ' + this.$store.state.activeClient.id
          });
        }
        this[orders] = data;
      } else {
        this.$vs.notify({
          title:'Ошибка' + api_method,
          text:response.data.error,
          color:'danger',
          iconPack:'feather',
          icon:'icon-alert-circle'
        })          
      }
    },
  },
  mounted() {
    this.getOrders('get_orders_missed_payment', 'missedPaymentOrders');
    this.getOrders('get_unfinished_orders', 'unfinishedOrders');
  }
}
</script>
