<!-- =========================================================================================
  File Name: Dashboard.vue
  Description: Основная страница для всех сотрудников
========================================================================================== -->

<template>
  <div id="dashboard">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/3 mb-base">
          <client-actions />
      </div>
      <div class="vx-col w-full lg:w-2/3 mb-base">
        <client-info />
        <missed-payment-orders v-if="$store.state.activeClient" />
        <visit />
      </div>
    </div>
  </div>
</template>

<script>
import clientActions from './components/ClientActions.vue'
import clientInfo from './components/ClientInfo.vue'
import missedPaymentOrders from './components/MissedPaymentOrders.vue'
import visit from './components/Visit.vue'

export default {
    data() {
        return {
        }
    },
    components: {
      clientActions,
      clientInfo,
      missedPaymentOrders,
      visit
    },
    methods: {
    }
}
</script>

<style lang="scss">
</style>
