<script>
import Registration from "@/views/pages/Registration.vue";

export default {
  mixins: [Registration],
  watch: {
    "$store.state.activeClient": {
      deep: true,
      handler(val) {
        this.getUserFormData(val);
      }
    }
  },
  methods: {
    getUserFormData(val) {
      this.userForm.data.new = Object.assign({}, val.info);
      this.userForm.data.new.client_type = this.userForm.data.new.client_type.id;
      this.userForm.data.new.barcode = val.barcode;
    },
    checkClientExists(resolve, reject) {
      resolve(true);
    },
    save(resolve, reject) {
      this.$store.commit("SET_ACTIVE_CLIENT_INFO", this.userForm.data.new);
      this.$store.dispatch("updateActiveCustomer");
      resolve(true);
      this.$emit('clientSaved');
    }
  },
  mounted() {
    this.getUserFormData(this.$store.state.activeClient);
  }
};
</script>
