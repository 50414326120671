<template>
  <div>
    <form>
      <div class="flex-column">
        <div style="font-size: 12px; margin-bottom: 8px; margin-top: -8px;">
          <p>Подтвердите обновление пароля выбранного клиента</p>
        </div>     
        <div style="color: lightgray; font-size: 12px; margin-bottom: 8px; margin-top: -8px;">
          <p>*После нажатия кнопки "Подтвердить" будет сгенерирован и отправлен клиенту новый пароль, а также отдельным сообщением будет отпралена его личная реферальная ссылка (промокод клиента остаётся прежним)</p>
        </div>   
        <input
          label = "id активного пользователя"
          v-model = "updateClientPassword.data.id"
          type = "hidden"
        />
        <div>
          <vs-button class="update-password-close-btn" color="success" @click="UpdateClientPassword" size="large" style="width: 100%;">
            ПОДТВЕРДИТЬ
          </vs-button>
        </div>
        
      </div>
    </form>
  </div>
</template>

<script>
var qs = require('qs');

export default {
  
  props: {
    customerId: {
      type: [Number, String]
    },
    getCurrentBonuses: {
      type: [Function]
    },
  },
  data() {
    return {
      responseMessage: false,
      updateClientPassword: {
        data: {
          id: this.customerId,
        }
      }
    };
  },

  computed: {
    activeClient() {
      return this.$store.state.activeClient;
    },
    clientInfo() {
      return this.$store.state.activeClient.info;
    },
    clientName() {
      let name = [this.clientInfo.lname, this.clientInfo.fname, this.clientInfo.father_name];
      return name.join(' ');
    },
    clientId() {
      let id = this.clientInfo.id;
      return id;
    },
  },
  
  methods: {
    UpdateClientPassword() {
      this.$http({
        method: 'post',
        url: '/udata/users/update_client_password.json',
        data: qs.stringify(this.updateClientPassword),
        withCredentials: true
      }).then(response => {
        this.response = response.data.result;
        this.close();
      });
    },
    getCurrCustomerId() {
      var curr_id = this.clientId;
      console.log(curr_id);
      return curr_id;
    },
    close() {
      let updatePasswordCloseBtn = document.querySelector('.update-password-close-btn');
      updatePasswordCloseBtn.setAttribute('disabled', 'disabled');
      updatePasswordCloseBtn.textContent = "НОВЫЙ ПАРОЛЬ ОТПРАВЛЕН КЛИЕНТУ";
    },
  },
  mounted() {
  }
};
</script>
