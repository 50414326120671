<!-- =========================================================================================
  File Name: Order.vue
  Description: Информация о заказе
========================================================================================== -->
<template>
  <vx-card :title="'Заказ #' + order.number" :subtitle="'ID: ' + order.id" class="relative">
    
    <order-status-chip :status="order.status" :name="order.status_name" />

    <div class="timeline-icon bg-primary list-view-item mb-base overflow-hidden">
        <feather-icon icon="CoffeeIcon" svgClasses="text-white stroke-current w-5 h-5"></feather-icon>
    </div>
    <div class="timeline-info">
        <div class="vx-row item-details no-gutter">
            <!-- ITEM NAME & DESC COL -->
            <div class="vx-col sm:w-3/4">
                <div class="p-2 pt-3 ">
                    <vs-table :data="order.items" noDataText="Нет товаров">
                        <template slot="thead">
                            <vs-th>Наименование</vs-th>
                            <vs-th>Количество</vs-th>
                            <vs-th>Цена со скидкой</vs-th>
                            <vs-th>Всего</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].id">
                                    <span>{{data[indextr].name}}</span>
                                </vs-td>
                                <vs-td :data="data[indextr].id">
                                    <span>{{data[indextr].item_amount}}</span>
                                </vs-td>
                                <vs-td :data="data[indextr].id">
                                    <span>{{data[indextr].price}} руб.</span>
                                </vs-td>
                                <vs-td :data="data[indextr].id">
                                    <span>{{data[indextr].total_price}} руб.</span>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>

            <!-- ACTION BUTTONS -->
            <div class="vx-col sm:w-1/4 w-full flex items-center">
                <div class="p-4 flex flex-col w-full border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">
                    <div class="my-6">
                        <h5 class="font-bold text-center">Сумма: {{ orderTotalPrice }}</h5>
                    </div>
                    <template v-if="$acl.check('admin')">
                        <div class="item-view-primary-action-btn p-3 rounded-lg flex flex-grow items-center justify-center cursor-pointer mb-3"
                                @click="confirmRemoveOrder()">
                            <feather-icon icon="XIcon" svgClasses="h-4 w-4" />
                            <span class="text-sm font-semibold ml-2">УДАЛИТЬ</span>
                        </div>
                        <div class="item-view-secondary-action-btn bg-primary p-3 rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer" 
                                @click="editOrder()">
                            <span class="text-xs font-semibold ml-2">РЕДАКТИРОВАТЬ</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="item-view-secondary-action-btn bg-primary p-3 rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer" 
                                @click="editOrder()">
                            <span class="text-xs font-semibold ml-2">РЕДАКТИРОВАТЬ</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="flex">
        <small class="text-grey activity-e-time">{{ $helpers.formatDateTime(order.time) }}</small>
    </div>
  </vx-card>
</template>

<script>
import orderStatusChip from './OrderStatusChip.vue'

export default {
    name: 'order',
    components: {
        orderStatusChip
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    computed: {
        orderTotalPrice() {
            return this.$store.getters['eCommerce/orderTotalPrice'](this.order)
        }
    },
    methods: {
        editOrder() {
          this.$store.dispatch('getOrder', [
            this.order.id,
            function(){}, 
            () => {
              this.$router.push({ name: 'edit-order' }).catch(() => {});
            } 
          ]);
        },
        confirmRemoveOrder() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Подтвердите удаление`,
                text: 'Подтвердите удаление заказа #' + this.order.number,
                acceptText: "Удалить",
                cancelText: "Отмена",
                accept: this.removeOrder
            })
        },
        removeOrder() {
            this.$store.dispatch('removeOrder', this.order);
        }
    }
}
</script>
