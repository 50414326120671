<!-- =========================================================================================
  File Name: ClientActions.vue
  Description: Действия с посетителем: регистрация, поиск, сканирование
========================================================================================== -->
<template>
  <div class="vx-col w-full lg:w-full mb-base">
    <vx-card slot="no-body" class="mb-4 text-center greet-user">
      <h2 class="mb-6">Действия с посетителем</h2>

      <div class="flex flex-wrap">
        <vs-button
          size="large"
          class="mb-2 mx-2 flex-auto"
          :disabled="notAllowedToChangeClient"
          @click="activePrompt = true"
        >
          По штрихкоду
        </vs-button>
        <vs-button
          size="large"
          class="mb-2 mx-2 flex-auto"
          :disabled="notAllowedToChangeClient"
          @click="$router.push({ name: 'customer-search' })"
        >
          Найти
        </vs-button>
        <vs-button
          size="large"
          class="mb-2 mx-2 flex-auto"
          :disabled="notAllowedToChangeClient"
          v-if="$acl.check('cashier')"
          @click="$router.push({ name: 'registration' })"
        >
          Регистрация
        </vs-button>
        <vs-button
          size="large"
          class="mb-2 mx-2 flex-auto"
          @click="$store.dispatch('endClientSession')"
        >
          <vx-tooltip text="Завершить работу с посетителем" position="right">
            Завершить
          </vx-tooltip>
        </vs-button>
      </div>
      <client-select-prompt :activePrompt.sync="activePrompt" />
    </vx-card>

    <vx-card
      class="mb-4"
      v-if="$store.state.activeClient"
      title="Статус посетителя"
    >
      <div class="flex flex-col flex-wrap">
        <div class="mt-2">
          <span>Текущий статус: </span>
          <span
            v-if="$store.state.activeClient.activeVisit"
            class="font-semibold"
            >в пространстве</span
          >
          <span v-else class="font-semibold">не в пространстве</span>
        </div>
        <div class="mt-2">
          <span>Зарегистрирован на мероприятие:</span>
          <p v-if="activeEventTariffItem" class="ml-2">
            <span class="font-semibold text-success">{{
              activeEventTariffItem.event_name
            }}</span>
            -
            <span class="text-grey">
              ID: {{ activeEventTariffItem.event_id }}
            </span>
            <br />
            <span class="italic">{{
              $helpers.formatDateTime(activeEventTariffItem.start_time)
            }}</span>
            -
            <span class="italic">{{
              $helpers.formatDateTime(activeEventTariffItem.end_time)
            }}</span>
          </p>
          <span v-else class="font-semibold">
            нет регистраций
          </span>
        </div>
        <api-button
          :click="cancelEvent"
          v-if="activeEventTariffItem"
          class="mr-4 mt-2 flex-auto"
        >
          Отменить регистрацию
        </api-button>
      </div>
    </vx-card>

    <vx-card
      class="mb-4"
      v-if="$store.state.activeClient"
      title="Действия с заказами"
    >
      <div class="flex flex-wrap">
        <api-button
          :click="addOrder"
          size="large"
          class="mb-2 mx-2 flex-auto"
        >
          Добавить заказ
        </api-button>
        <api-button
          :click="() => $router.push({ name: 'edit-order' })"
          v-if="Object.keys($store.state.editedOrder).length != 0"
          class="mb-2 mx-2 flex-auto"
        >
          Вернуться к редактированию заказа
        </api-button>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ClientSelectPrompt from "./ClientSelectPrompt.vue";

export default {
  name: "client-actions",
  components: {
    ClientSelectPrompt
  },
  data() {
    return {
      promptClientBarcode: null,
      activePrompt: false
    };
  },
  computed: {
    notAllowedToChangeClient() {
      return (
        !this.$store.getters["activeTimeTariff"] &&
        this.$store.state.activeClient.activeVisit
      );
    },
    activeEventTariffItem() {
      return this.$store.getters["activeEventTariffItem"];
    }
  },
  methods: {
    addOrder() {
      this.$store.dispatch("addOrder", {
        isTimeOrder: false,
        callback: () => {
          this.$router.push({ name: "edit-order" });
        }
      });
    },
    cancelEvent() {
      this.$store.dispatch(
        "eCommerce/endTimeTariff",
        this.activeEventTariffItem
      );
    }
  }
};
</script>
