<!-- =========================================================================================
  File Name: ClientBonusHistory.vue
  Description: История заказов посетителя в табличном виде
========================================================================================== -->
<template>
  <div class="mt-4">
    <vs-table 
        :data="data"
        max-items="10"
        pagination
        striped
        hoverFlat
        noDataText="Бонусных транзакций нет"
        >

      <template slot="thead">
        <vs-th>№ заказа</vs-th>
        <vs-th>Дата/время</vs-th>
        <vs-th>Отправитель</vs-th>
        <vs-th>Получатель</vs-th>
        <vs-th>Бонусы</vs-th>
        <vs-th>Комментарий</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr">
            <span v-if="tr.order_id == ''"> - </span>
            <span v-else>Заказ № </span>
            {{tr.order_id}}
          </vs-td>
          <vs-td :data="tr">{{tr.transaction_time}}</vs-td>
          <vs-td :data="tr">{{tr.referal}}</vs-td>
          <vs-td :data="tr">{{tr.referer}}</vs-td>
          <vs-td :data="tr">{{tr.bonuses}}</vs-td>
          <vs-td :data="tr">{{tr.comment}}</vs-td>
        </vs-tr>
      </template>

    </vs-table>

    <div class="mt-5 flex">
      <h6 class="mt-auto mb-auto mr-4">Бонусный баланс посетителя:</h6>
      <div class="flex items-center">
        <span class="store_balance" v-if="!showBalance"> {{ this.$store.state.activeClient.info.bonus }}</span>
        <span class="bonusBalanse" v-if="showBalance"> {{ bonusBalance }}</span>
      </div>
    </div>
  </div>
</template>

<script>
var qs = require('qs');
export default {
  name: 'client-bonus-history',
  props: [
    'visit_id', 'customer_id', 'flat', 'editOrderHandler'
  ],
  data() {
    return {
      data: [],
      scrollSettings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
      bonusBalance: 'no_bonus',
    }
  },
  methods: {
    async getData() {
      await this.$http({
        method: 'get',
        url: '/udata/users/get_bonus_transactions/.json' 
            + '?customer_id=' + this.customer_id,
        withCredentials: true
      }).then((response) => {
          if (!response.data.error) {
              this.data = Object.values(response.data.items);
          } else {
            this.$vs.notify({
              title:'Ошибка',
              text: response.data.error,
              color:'warning',
              iconPack: 'feather',
              icon:'icon-alert-circle'
            })
          }
      })
      .catch((error) => {
        this.$vs.notify({
          title:'Ошибка',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      });
    },
    getCurrentBonuses() {
      this.showBalance = false;
      if (this.$store.state.activeClient.id) {
        let data = {
              'data': {
                  'customer_id': this.$store.state.activeClient.id,
              }
          }
          this.$http({
              method: 'post',
              url: '/udata/users/get_customer_bonuses/.json',
              data: qs.stringify(data),
              withCredentials: true
          }).then((response) => {
              if (response.data.customer_bonuses) {
                  this.bonusBalance = response.data.customer_bonuses;
                  this.showBalance = true;
              } 
              else {
                  this.bonusBalance = 'no_bonus';
                  this.showBalance = true;
              }
          })
      }
    }
  },
  mounted() {
    this.getData();
  },
  created() {
    this.getCurrentBonuses();
  }
}
</script>
