<!-- =========================================================================================
  File Name: Visit.vue
  Description: Информация о текущем визите посетителя
========================================================================================== -->
<template>
  <div class="vx-col w-full lg:w-full mb-base"
      v-show="$store.state.activeClient">
    <vx-card title="Текущий визит">
      <div class="mb-4" v-if="$acl.check('cashier')">
        <api-button 
          :click="addVisit"
          v-show="!$store.state.activeClient.activeVisit"
          size="large" class="mr-4" ref="addVisitButton">
            Добавить визит
        </api-button>

        <template v-if="$store.state.activeClient.activeVisit">
          <template v-if="!$store.state.activeClient.is_temp_card">
            <api-button 
              :click="() => {activeTempCardPrompt = true}"
              v-if="$acl.check('admin')"
              class="mr-4 mb-2"
              :disabled="isTempCardActive">
                <span v-if="!isTempCardActive">Добавить временную карту</span>
                <span v-else>Временная карта ID: {{$store.state.activeClient.activeVisit.temp_card}}</span>
            </api-button>
            <vs-prompt
                ref="tempCardIdPrompt"
                @accept="acceptTempCardPrompt"
                @close="promptTempCardBarcode=''"
                @cancel="promptTempCardBarcode=''"
                title="Введите штрихкод карты"
                accept-text="Выбрать"
                cancel-text="Отмена"
                :active.sync="activeTempCardPrompt">
              <vs-input ref="tempCardIdInput" placeholder="Штрих код временной карты" 
                v-model="promptTempCardBarcode" class="mt-3 w-full" />
            </vs-prompt>
          </template>

          <api-button
            :click="registrationEvent"
            :disabled="!notAllowedToAddEvent"
            class="mr-4 mb-2">
              Зарегистрировать на мероприятие
          </api-button>
          <api-button
            :click="endVisit"
            :disabled="notAllowedToEndVisit"
            class="mr-4 mb-2" color="success">
              Завершить визит
          </api-button>
          <api-button
            :click="() => {activeEndVisitPrompt = true}"
            class="mr-4 mb-2" color="danger">
              Завершить визит без оплат
          </api-button>

          <vs-alert :active="notAllowedToEndVisit" color="warning" icon-pack="feather" icon="icon-info">
            <span>Для завершения визита необходимо завершить текущий тариф и зафиксировать оплаты по всем заказам</span>
          </vs-alert>
        </template>
      </div>

      <template v-if="$store.state.activeClient.activeVisit">
        <div class="mb-4"
          v-show="$acl.check('cashier') && !timeOrder">
            <api-button 
              :click="addTimeOrder"
              size="large">
                Добавить заказ-время
            </api-button>
        </div>
        <ul class="activity-timeline">
          <li v-if="timeOrder">
            <time-order v-bind:order="timeOrder" :tariffList="tariffList" />
          </li>
          <li v-for="order in $store.getters['orders'](3)" v-bind:key="order.id">
            <order v-bind:order="order" />
          </li>
        </ul>

        <!-- Попап завершения визита -->
        <vs-popup
            fullscreen
            title="Итоговый расчет по визиту"
            :active.sync="activePaymentsPrompt">
          <time-order-payments 
            :order="timeOrder" 
            :callback="fixPaymentsCallback"
            :acceptText="'ЗАВЕРШИТЬ ВИЗИТ'"/>
        </vs-popup>
        <!-- Попап завершения визита без оплат -->
        <vs-prompt
            :active.sync="activeEndVisitPrompt"
            @accept="endVisitWithoutPayments"
            title="Завершение визита"
            accept-text="Завершить"
            cancel-text="Отмена">
          Вы уверены что хотите завершить визит без оплат?
        </vs-prompt>
      </template>

      <template v-else>
        <p v-if="$acl.check('barista')">Что-то пошло не так! Где визит, Лебовски?</p>
      </template>
    </vx-card>
       
  </div>
</template>

<script>
import order from './Order.vue'
import timeOrder from './TimeOrder.vue'
import TimeOrderPayments from '../apps/eCommerce/TimeOrderPayments.vue'

export default {
  name: 'visit',
  data() {
    return {
      activeEndVisitPrompt: false,
      activePaymentsPrompt: false,
      activeTempCardPrompt: false,
      promptTempCardBarcode: null,
      tariffList: []
    }
  },
  computed: {
    isTempCardActive() {
      return !!this.$store.state.activeClient.activeVisit.temp_card;
    },
    notAllowedToEndVisit() {
      return this.$store.getters['activeTimeTariff'] != false || this.$store.getters['isOrdersWithoutFixedPayments'];
    },
    notAllowedToAddEvent() {
      return this.$acl.check('cashier') && !this.$store.getters.activeEventTariffItem;
    },
    timeOrder() {
      return this.$store.getters['timeOrder'];
    }
  },
  components: {
    order,
    timeOrder,
    TimeOrderPayments
  },
  methods: {
    addTimeOrder() {
      this.$store.dispatch('addOrder', {
        isTimeOrder: true,
        callback: () => {
          this.$router.push({ name: 'edit-order' });
        } 
      });
    },
    registrationEvent() {
      this.$router.push({ name: 'registration-event' }).catch(() => {});
    },
    async addVisit() {
      await this.$store.dispatch('addVisit');
    },
    endVisit() {
      this.activePaymentsPrompt = true;
    },
    async endVisitWithoutPayments() {
      this.$vs.loading();
      await this.$store.dispatch('eCommerce/endActiveTimeTariff');
      await this.$store.dispatch('endVisit', true);
      this.$vs.loading.close();
    },
    fixPaymentsCallback() {
      this.$store.dispatch('endVisit');
      this.activePaymentsPrompt = false;  
    },
    async acceptTempCardPrompt() {
      this.$vs.loading();
      await this.$store.dispatch('addTempCard', this.promptTempCardBarcode);
      this.$vs.loading.close();
    },
    
    getActiveTimeTariffs() {
      // TODO wrap API calls to UMI backend for data assignment into helper function
      fetch("/udata/catalog/get_time_tariffs/active.json")
        .then(response => response.json())
        .then(response => {
          if (response.item) {
            this.tariffList = Object.values(response.item);
          }
        })
        .catch(e => {
          this.$vs.notify({
            color:'danger',
            title:'Не удалось получить список действующих тарифов',
            text: e
          })
        });
    }
  },
  mounted() {
    this.getActiveTimeTariffs();
  }
}
</script>
