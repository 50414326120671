<!-- =========================================================================================
  File Name: ClientComments.vue
  Description: Комментарии к текущему посетителю
========================================================================================== -->
<template>
  <div class="mt-4 border border-solid d-theme-border-grey-light border-b-0 border-r-0 border-l-0">
    <div class="comments-container mt-4">
      <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="scrollSettings">
        <ul class="user-comments-list"
          v-if="activeClient.comments">
          <li v-for="(comment, index) in activeClient.comments" :key="index" class="commented-user flex items-center mb-4">
              <!-- <div class="mr-3"><vs-avatar class="m-0" :src="comment.author.photo" size="30px" /></div> -->
              <div class="leading-tight">
                <p class="font-medium">{{ comment.author.name }}</p>
                <p class="text-grey-light text-xs">{{ $helpers.formatDate(comment.publish_time) }}</p>
                <span class="text-sm">{{ comment.message }}</span>
              </div>
          </li>
        </ul>
      </VuePerfectScrollbar>
    </div>
    <div class="post-comment">
      <vs-textarea label="Добавить комментарий" class="mb-2" v-model="commentbox" />
      <vs-button size="small" @click="addComment">Добавить комментарий</vs-button>
    </div>
  </div>
</template>

<script>
var qs = require('qs');
import VuePerfectScrollbar from 'vue-perfect-scrollbar'


export default {
    name: 'client-comments',
    data() {
      return {
        commentbox: '',
        scrollSettings: { // perfectscrollbar settings
            maxScrollbarLength: 60,
            wheelSpeed: .60,
        },
      }
    },
    computed: {
      activeClient() {
        return this.$store.state.activeClient;
      }
    },
    methods: {
      addComment() {
        let payload = {
          comment: this.commentbox,
          customer_id: this.activeClient.id,
        }
        this.$http({
          method: 'post',
          url: '/udata/users/add_client_comment/',
          data: qs.stringify(payload),
          withCredentials: true
        }).then(() => {
          this.$store.dispatch('getClientComments');
          this.$vs.notify({
            color:'success',
            title:'Комментарий успешно добавлен'
          })
        }).catch((err) => {
          this.$vs.notify({
              color:'danger',
              title:'Ошибка addComment',
              text:err
          })                            
        })
      },
      getComments() {

      }
    },
    components: {
      VuePerfectScrollbar
    },
    mounted() {
      this.$store.dispatch('getClientComments');
    }
}
</script>
