<template>
  <div>
    <form>
      <div class="flex-column">
        <vs-input
          label = "Введите количество бонусов"
          v-model = "manualAddBonuses.data.bonuses"
          class = "flex-none mb-4"
          style = "width: 100%"
          title="Для списания бонусов введите отрицательное число"
        />
        <div style="color: lightgray; font-size: 12px; margin-bottom: 8px; margin-top: -8px;">
          <p>*Для списания бонусов введите отрицательное число</p>
          <p>Если количество списываемых бонусов будет превышать количество бонусов на счёте клиента, то бонусный счёт клиента будет приравнен к нулю</p>  
        </div>     
        <vs-input
          label = "Введите комментарий"
          v-model = "manualAddBonuses.data.comment"
          class = "flex-none mb-4"
          type = "text"
          style = "width: 100%;"
          v-validate="{ required: true, regex: /^[а-яА-ЯёЁ a-zA-Z0-9]{4,50}$/ }"
        />
        <div style="color: lightgray; font-size: 12px; margin-bottom: 8px; margin-top: -8px;">
          <p>*Поле "Комментарий" обязательно для заполнения</p>
        </div>   
        <input
          label = "id активного пользователя"
          v-model = "manualAddBonuses.data.id"
          type = "hidden"
        />

        <!-- <div v-if="this.manualAddbonuses.data.comment.length == 0">
          <vs-button disabled class="" color="success" @click="ManualAddBonuses" size="large" style="width: 100%;" title="Введите комментарий">
            ПОДТВЕРДИТЬ
          </vs-button>
        </div> -->
        <div>
          <vs-button :disabled="!isFormValid" class="" color="success" @click="ManualAddBonuses" size="large" style="width: 100%;">
            ПОДТВЕРДИТЬ
          </vs-button>
        </div>
        
      </div>
    </form>
  </div>
</template>

<script>
var qs = require('qs');

export default {
  
  props: {
    customerId: {
      type: [Number, String]
    },
    getCurrentBonuses: {
      type: [Function]
    },
  },
  data() {
    return {
      responseMessage: false,
      manualAddBonuses: {
        data: {
          bonuses: '0',
          comment: '',
          id: this.customerId,
        }
      }
    };
  },

  computed: {
    activeClient() {
      return this.$store.state.activeClient;
    },
    clientInfo() {
      return this.$store.state.activeClient.info;
    },
    clientName() {
      let name = [this.clientInfo.lname, this.clientInfo.fname, this.clientInfo.father_name];
      return name.join(' ');
    },
    clientBonus() {
      let bonus = this.clientInfo.bonus;
      return bonus;
    },
    clientId() {
      let id = this.clientInfo.id;
      return id;
    },
    isFormValid () {
      return Object.keys(this.fields).every(field => this.fields[field].valid);
    },
  },
  
  methods: {
    ManualAddBonuses() {
      this.$http({
        method: 'post',
        url: '/udata/users/manual_add_bonuses.json',
        data: qs.stringify(this.manualAddBonuses),
        withCredentials: true
      }).then(response => {
        this.response = response.data.result;
        this.close();
      });
    },
    getCurrCustomerId() {
      var curr_id = this.clientId;
      console.log(curr_id);
      return curr_id;
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
  }
};
</script>
