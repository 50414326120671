<!-- =========================================================================================
  File Name: TimeOrder.vue
  Description: Информация о заказе с временным тарифом
========================================================================================== -->
<template>
  <vx-card :title="'Заказ-время #' + order.number" :subtitle="'ID: ' + order.id">
    <div class="timeline-icon bg-primary list-view-item mb-base overflow-hidden">
        <feather-icon icon="ClockIcon" svgClasses="text-white stroke-current w-5 h-5"></feather-icon>
    </div>
    <div class="timeline-info">
        <div class="vx-row item-details no-gutter">
            <!-- ITEM NAME & DESC COL -->
            <div class="vx-col sm:w-3/4">
                <div class="p-2 pt-3 w-full border border-solid d-theme-border-grey-light border-l-0 border-t-0 border-b-0">
                    
                    <ul>
                        <li class="border border-solid d-theme-border-grey-light border-l-0 border-t-0 border-r-0 pb-1"
                                v-for="item in itemsWithoutActiveTariff" v-bind:key="item.id">
                            <div class="vx-row">
                                <div class="vx-col w-full sm:w-auto">
                                    <p class="font-semibold text-lg">Тариф: <span class="text-primary">{{item.name}}</span></p>
                                    <p class="text-xs text-grey">ID: {{item.id}}</p>
                                    <p class="italic" v-if="item.event_name">Мероприятие:  <span class="text-success">{{item.event_name}}</span></p>
                                    <p>
                                        Время: {{$helpers.formatDateTime(item.start_time)}} 
                                        <span v-if="item.end_time"> - {{$helpers.formatDateTime(item.end_time)}} </span>
                                    </p>
                                    <p class="font-semibold">Всего: {{item.item_amount}} мин. x {{item.price}} руб. =  {{item.total_price}} руб.</p>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <template v-if ="tariffList !== false">
                        <vx-input-group v-if="!activeTimeTariff">
                            <template v-if="$acl.check('cashier')">
                                <v-select :options="tariffList" v-model="selectedTariff" label="name" :clearable="false">
                                    <span slot="no-options">
                                        В данное время нет доступных тарифов
                                    </span>
                                </v-select>
                                <template slot="append">
                                    <div class="append-text btn-addon">
                                        <api-button :click="startTimeTariff" color="success">Начать</api-button>
                                    </div>
                                </template>
                            </template>
                        </vx-input-group>
                        <div v-else>
                            <p v-if="activeTimeTariff.event_id" class="font-semibold text-lg">Текущее мероприятие:</p>
                            <p v-else class="font-semibold text-lg">Текущий тариф:</p>
                            <p class="italic" v-if="activeTimeTariff.event_name">{{activeTimeTariff.event_name}}</p>
                            <p class="text-xs text-grey">ID: {{activeTimeTariff.id}}</p>
                            <vx-input-group class="my-1">
                                <v-select :value="activeTimeTariff" disabled label="name" :clearable="false"/>
                                <template slot="append" v-if="$acl.check('cashier')">
                                    <div class="append-text btn-addon">
                                        <api-button :click="endActiveTimeTariff" color="danger">Завершить</api-button>
                                    </div>
                                </template>
                            </vx-input-group>
                            <p>Время: {{$helpers.formatDateTime(activeTimeTariff.start_time)}} - {{$helpers.formatDateTime($store.state.now)}}</p>
                            <p class="font-semibold">Всего: {{activeTimeTariff.item_amount}} мин. x {{activeTimeTariff.price}} руб. =  {{activeTimeTariff.item_amount * activeTimeTariff.price}} руб.</p>
                        </div>
                    </template>
                </div>
            </div>
            <div class="vx-col sm:w-1/4 w-full flex items-center">
                <div class="p-4 flex flex-col">
                    <div class="my-6">
                        <h5 class="font-bold text-center">Сумма: {{ orderTotalPrice }} руб.</h5>
                    </div>
                    <div v-if="tariffList === false" 
                        class="item-view-secondary-action-btn bg-primary p-3 rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer" 
                            @click="editOrder()">
                        <span class="text-xs font-semibold ml-2">РЕДАКТИРОВАТЬ</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <small class="text-grey activity-e-time">{{ $helpers.formatDateTime(order.time) }}</small>
  </vx-card>
</template>

<script>
import order from './Order.vue'

export default {
    extends: order,
    name: 'time-order',
    props: ['tariffList'],
    data() { 
        return {
            selectedTariff: null
        }
    },
    computed: {
        itemsWithoutActiveTariff() {
            return this.$store.getters['itemsWithoutActiveTariff'](this.order);
        },
        activeTimeTariff() {
            return this.$store.getters['activeTimeTariff'];
        },
    },
    methods: {
        startTimeTariff() {
            this.$store.dispatch('eCommerce/startTimeTariff', this.selectedTariff);
        },
        endActiveTimeTariff() {
            this.$store.dispatch('eCommerce/endActiveTimeTariff');
        }
    },
    mounted() {
        this.selectedTariff = this.tariffList[0];
    }
}
</script>

<style lang="scss">
.activity-timeline {
    li:last-of-type {
        border-bottom-width: 0 !important
    }
}
</style>